<template>
	<div class="block-inner">
		<header @change="$emit('edit')">
			<div v-if="block.id">
				<RichEditor v-model="block.content" :inline="true" :block-id="block.id" :paste-tags="false" placeholder="Write a question" @input="$emit('edit')" />
			</div>
		</header>

		<div class="input-group repeat">
			<section v-if="block.items" class="input-group-rows">
				<div v-for="(item, index) in block.items.lhs" :key="index" class="input-group-row" @change="$emit('edit')">
					<div class="input-group-col">
						<input v-if="item.type === 'text'" v-model="item.label" type="text" placeholder="Add a label..." />
						<DropZone v-else-if="item.type === 'image'" :uploaded-files="item.file.name ? [item.file] : []" :accept-types="allowedFileTypes" :compact="true" @upload="files => addFile(files, item)" />
						<div v-else class="select-input">
							Select <span @click="() => item.type = 'text'">text</span> or <span @click="() => item.type = 'image'">image</span>
						</div>
					</div>
					<div class="input-group-col">
						<input v-if="block.items.rhs[index].type === 'text'" v-model="block.items.rhs[index].label" type="text" placeholder="Add a label..." />
						<DropZone v-else-if="block.items.rhs[index].type === 'image'" :uploaded-files="block.items.rhs[index].file.name ? [block.items.rhs[index].file] : []" :accept-types="allowedFileTypes" :compact="true" @upload="files => addFile(files, item)" />
						<div v-else class="select-input">
							Select <span @click="() => block.items.rhs[index].type = 'text'">text</span> or <span @click="() => block.items.rhs[index].type = 'image'">image</span>
						</div>
					</div>
					<BaseButton type="primary rounded" @click="removeInput(index)">
						<IconCross :width="12" :height="12" />
					</BaseButton>
				</div>
			</section>
			<BaseButton type="primary rounded" @click="addInput">
				<IconAdd :width="16" :height="16" :strokewidth="3" />
			</BaseButton>
		</div>
	</div>
</template>

<script>
	import IconAdd from '../icons/IconAdd';
	import IconCross from '../icons/IconCross';
	import BaseButton from '../BaseButton';
	import RichEditor from '../RichEditor';
	import DropZone from '../DropZone.vue';
	import {v4} from 'uuid';
	import Bus from '../../inc/bus';
	import ObjectStore from '../../inc/objectStore';

	export default {
		components: {
			IconAdd,
			IconCross,
			BaseButton,
			RichEditor,
			DropZone
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				allowedFileTypes: [
					'image/png',
					'image/jpeg'
				]
			};
		},
		methods: {
			addInput() {
				if(!this.block.items) {
					this.$set(this.block, 'items', {lhs: [], rhs: []});
				}

				this.block.items.lhs.push(this.defaultItem());
				this.block.items.rhs.push(this.defaultItem());
				this.$emit('edit');
			},
			addFile(fileList, item) {
				const file = fileList[0];

				if(this.allowedFileTypes.includes('*') || this.allowedFileTypes.includes(file.type)) {
					ObjectStore.upload(file)
						.then(uploaded => {
							item.file = uploaded;
							this.$emit('edit');
						});
				}
				else {
					Bus.emit('error', `File type not allowed: ${file.type}.`);
				}
			},
			removeInput(index) {
				this.block.items.lhs.splice(index, 1);
				this.block.items.rhs.splice(index, 1);
				this.$emit('edit');
			},
			defaultItem() {
				return {
					id: v4(),
					label: '',
					file: {},
					type: null
				};
			}
		}
	};
</script>

<style lang="scss" scoped>
	header {
		margin-bottom: $default_padding * 0.5;
	}

	.select-input {
		flex: 1;
		text-align: center;
		font-size: 1.25em;
		line-height: 1.5em;

		span {
			font-weight: $font__semibold;
			cursor: pointer;
		}
	}

	.input-group::v-deep {
		.dropzone,
		input {
			margin-bottom: 0;
			flex: 1;
		}

		.input-group-row {
			.button {
				align-self: center;
			}
		}
	}

	.input-group {
		display: flex;
		flex-flow: column nowrap;

		.input-group-row {
			display: flex;
			flex-flow: row nowrap;
			margin-bottom: 10px;
			column-gap: 20px;
		}

		.input-group-col {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			flex: 1;
			column-gap: 10px;
			border: 2px dashed $border_color;
			padding: 5px;
			border-radius: $border_radius;
		}

		& > .button {
			align-self: center;
		}

		button {
			background-color: $color__dark;

			&:hover {
				background-color: rgba($color: $color__dark, $alpha: 0.8);
			}
		}
	}
</style>